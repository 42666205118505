@use "sass:math";

@import "./base/modernize";
@import "mixins.scss";

$font-color: gray;
$share-sprite-large-height: 50px;
$share-sprite-large-width: 49px;
$share-sprite-large-y-offset: 20px;
$share-sprite-large-x-margin: 20px;
$share-sprite-large-x-origin: 31px;
$share-sprite-large-y-origin: 94px;
$section-margin: 20px;
$input-margin: 15px;
$blue: #0077AA;
$orange: #ed9d2c;
$light-bg-color-modal: #e9e9e9;
$dark-bg-color-modal: #000014;
$dark-bg-color-modal-rgb: rgba(10, 13, 22, 0.96);
$font-size-embed: 12px;
$font-size-embed-small: 10px;
$font-color-embed: rgba(255, 255, 255, 0.5);
$section-margin-embed: 10px;
$input-margin-embed: 5px;
$textarea-text-color: #555;
$textarea-disabled-text-color: #888;
$textarea-disabled-background: rgba(207, 207, 207, 0.21);
$max-field-width: 292px;
$embed-player-max-field-width: 307px;
$max-div-width: $max-field-width + 22px;
$small-embed-player-max-field-width: 266px;
$modal-grey: #A9A9A9;
$border-color: #D0D0D0;

$modal_height_default: 536px;
$modal_height_email_expanded: 691px;
$modal_height_embed_expanded: 590px;

$scale_small: .47;
$scale_norm: .59;
$scale_large: .71;
$scale_xl: .83;
$scale_xxl: .89;

$width_small: math.div(100%, $scale_small);
$width_norm: math.div(100%, $scale_norm);
$width_large: math.div(100%, $scale_large);
$width_xl: math.div(100%, $scale_xl);
$width_xxl: math.div(100%, $scale_xxl);

.overflow_hidden {
  overflow: hidden;
}

// Modal share popup positioning
#homepage-modal-share, #new-list-modal-share, #slideview-modal-share {
  position: fixed;
  top: 0;
  bottom: 0;
}

/*******************/
/* UNIVERSAL RULES */
/*******************/
.modal-share {
  .modal-content-wrapper {
    max-height: 100%
  }

  a.share-link,
  button.modal-close,
  input[type="text"],
  textarea,
  select {
    border-radius: 4px;
  }
  // focus styles
  a.share-link:focus,
  button.modal-close:focus,
  input[type="text"]:focus,
  textarea:focus,
  select:focus,
  a.share-link:focus-visible,
  button.modal-close:focus-visible,
  input[type="text"]:focus-visible,
  textarea:focus-visible,
  select:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px 1px #e9e9e9, 0px 0px 0px 3px rgba(2, 126, 176, 0.7);
  }
  select:focus {
    border-radius: 2px;
  }

  input, select[disabled], textarea, select[readonly] {
    &:not(#share-email-send) {
      background-color: #FFF !important;
      border: 1px solid $border-color;
      outline: none;
      &[disabled], &[readonly] {
        color: $modal-grey !important;
      }
      &:focus {
        background-color: #F6FCFF !important;
        &.error {
          border-color: #FF3950 !important;
          background-color: #FFF0F0 !important;
        }
      }
      &.error {
        border-color: #C93950 !important;
        background-color: #FFE0E0 !important;
      }
    }
  }
  .share-tab {
    width: 470px;
  }
  color: #646464;
  display: none;
  margin: auto;
  background: rgba(0,0,0,0.5);
  width: 100%;
  z-index: 10001;
  header, .header {
    background: inherit;
    filter: none;
    @include box-shadow(inherit);
  }
  .modal-close {
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 26px;
    justify-content: center;
    min-width: 26px;
    overflow: hidden;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 4px;
    z-index: 1000;

    &:before {
      content: "\00d7";
    }
  }
  // Override button globals.
  button.modal-close {
    margin: 0;
    padding: 0;
    border: none;
    color: inherit;
    background-color: transparent;
    line-height: inherit;

    &:before {
      vertical-align: top;
    }
  }
  .section {
    padding: 0 0 20px 0;
    &.share-link-container {
      padding-bottom: 0;
    }
    #share-embed-wp {
      cursor: pointer;
    }
  }
  textarea, input {
    border-color: $border-color;
    width: 100%;
    height: 38px;
    line-height: 1.2;
    font-size: 16px;
    padding: 0 0 0 9px;
    overflow: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include box-sizing(border-box);
    &[type='checkbox'], &[type='submit'] {
      height: auto;
      width: auto;
      line-height: auto;
    }
    &#share-embed-link {
      line-height: 38px;
    }
    &.j-triggered {
      line-height: auto;
    }
  }
  .modal-popup {
    position: relative;
    & input, textarea {
      margin: 0;
      font-weight: normal !important;
      &.button {
        padding: 3px;
        font-weight: 500;
      }
    }
    textarea {
      resize: none;
    }
    .tabs {
      border-bottom: none;
      text-align: left;
      line-height: 13px;
      float: none;
      a {
        color: inherit;
        text-decoration: none;
        background: none;
        display: inline;
        font-weight: 400;
        font-size: 22px;
        border-bottom: 3px solid transparent;
        letter-spacing: 1px;
      }
      hr {
        max-width: 100%;
        &.divider {
          border: 0;
          height: 0;
          border-bottom: 1px solid $border-color;
          margin-top: 5px;
          margin-bottom: 25px;
        }
      }
    }

    .modal-content {
      overflow: hidden;
      padding: 30px;
      background: $light-bg-color-modal;
      max-width: 530px;
      margin: 0 auto;
      position: relative;

      .header {
        display: inline-block;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        padding: 0 0 10px 0;
        margin:0;
      }
      .share-email {
        .email-flash {
          display: none;
          color: #973A3A;
          float: left;
          font-size: 16px;
          margin-top: 15px;
        }
        #share-email-form {
          margin: 0;
        }
        #share-email-msg {
          max-height: 70px;
          min-height: 50px;
          padding-top: 5px;
          overflow: scroll;
          $transition: border linear .2s, box-shadow linear .2s, background-color linear .2s, height linear .2s;
          @include transition($transition);
          &.msg-expanded {
            max-height: 100px;
            height: 100px;
          }
        }
        ::-webkit-input-placeholder {
            color: $modal-grey;
            opacity: 1;
            line-height: 16px;
          }

        :-moz-placeholder { /* Firefox 18- */
            color: $modal-grey;
            opacity: 1;
          }

        ::-moz-placeholder {  /* Firefox 19+ */
            color: $modal-grey;
            opacity: 1;
          }

        :-ms-input-placeholder {
            color: $modal-grey;
            opacity: 1;
            line-height: 16px;
          }
        .share-email-expand {
          #share-email-send {
            height: 34px;
            line-height: 1.2;
            font-family: inherit;
            overflow: auto;
            background: #F28F02;
            color: #FFFFFF;
            float: right;
            border: none;
            width: 70px;
            text-shadow: none;
            &.sending {
              -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
              filter: alpha(opacity=50);
              opacity: .5;
              background-image: url('/images/spinner_no_bg.gif');
              background-repeat: no-repeat;
              background-position: 25px 7px;
              background-size: 20px;
            }
            &:hover {
              -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
              filter: alpha(opacity=70);
              opacity: .7;
            }
          }
        }
        #email-sent {
          display: none;
          background: #5AC737;
          text-align: center;
          vertical-align: middle;
          padding: 10px;
          font-size: 16px;
          overflow: auto;
          border-radius: 2px;
          @include box-sizing(border-box);
          margin-bottom: 0;
          .success-text {
            display: inline-block;
            font-size: 16px;
            vertical-align: middle;
            color: white;
            float: left;
            line-height: 35px;
          }
          #send-another-email {
            border-radius: 2px;
            border: none;
            background: #FFFFFF;
            line-height: 28px;
            float: right;
            padding-left: 13px;
            padding-right: 13px;
          }
        }
      }
      .share-social-list {
        vertical-align: top;
        display: inline-block;
        padding: 0;
        margin-bottom: 20px;
        margin-left: 0;
        text-align: left;
        height: $share-sprite-large-height;
        border-radius: 2px;
        li {
          list-style: none;
          text-decoration: none;
          display: inline-block;
          width: $share-sprite-large-width;
          height: $share-sprite-large-height;
          background-image: url('/images/modal_share_sprites.png');
          background-repeat: no-repeat;
          margin: 0 $share-sprite-large-x-margin 0 0;
          border-radius: 4px;
          .social-hover {
            height: inherit;
            @include box-shadow(none);
            a {
              height: 100%;
              text-indent: -9999px;
              display: block;
            }
          }
          &:hover {
            -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
            filter: alpha(opacity=70);
            opacity: .7;
          }
        }
        .linkedin {
          background-position: -$share-sprite-large-x-origin (-$share-sprite-large-y-origin - ($share-sprite-large-y-offset + $share-sprite-large-height) * 0);
        }
        .facebook {
          background-position: -$share-sprite-large-x-origin (-$share-sprite-large-y-origin - ($share-sprite-large-y-offset + $share-sprite-large-height) * 1);
        }
        .twitter {
          background-position: -$share-sprite-large-x-origin (-$share-sprite-large-y-origin - ($share-sprite-large-y-offset + $share-sprite-large-height) * 2);
        }
        .googleplus {
          background-position: -$share-sprite-large-x-origin (-$share-sprite-large-y-origin - ($share-sprite-large-y-offset + $share-sprite-large-height) * 3);
        }
      }
      .share-link-container {
        display: block;
        vertical-align: top;
        #share-link-url {
          cursor: pointer;
        }
      }
      .share-embed-buttons {
        text-align: center;
      }
      #share-embed-link {
        cursor: pointer;
        white-space: nowrap;
        &.j-triggered {
          white-space: normal;
          overflow-y: scroll;
          overflow-x: hidden;
          -ms-overflow-x: hidden;
          -ms-overflow-y: scroll;
          font-size: 12px;
          line-height: 16px;
          height: 64px;
        }
      }
      .title {
        font-weight: 400;
        vertical-align: bottom;
      }
      .share-embed-options {
        padding-top: 6px;
        select, span, input, div {
          vertical-align: middle !important;
          @include box-sizing(border-box);
        }
        &> div {
          display: inline-block;
        }
        .use-ssl-container {
          display:inline-block;
          overflow: hidden;
          border: 1px solid $border-color;
          width: 22px;
          height: 22px;
        }
        .embed-size-picker {
          width: 76px;
          color: #646464;
          background-position: 30px -22px;
        }
        .embed-related-cbox, .embed-use-ssl-cbox {
          width: 48px;
          height: 88px;
          position: relative;
          top: -3px;
          left: -3px;
          &:checked {
            background-image: url('/images/modal_share_sprites.png');
            background-position: 63px -50px;
          }
        }
        .embed-show-related, .embed-use-ssl {
          float: left;
          width: 33%;
        }
      }
      .share-wp {
        margin-bottom: 0;
      }
    }
  }

  .j-share-expand {
    display:none;

    &> input, &> textarea {
      margin-top: 10px;
    }
  }

  .embed-start,
  .share-start {
    float: right;
  }
  .embed-size-picker,
  .embed-start-picker,
  .share-start-picker {
    border:none !important;
    outline: none !important;
    height: 22px;
    font-size: 11px;
    margin-bottom: 0;
    line-height: 12px;
    padding: 4px;
    background-image: url('/images/modal_share_sprites.png');
    text-indent: .01px;
    text-overflow: "";
  }
  .embed-start-picker,
  .share-start-picker {
    width: 66px;
    background-position: 20px -22px;
  }

  #share-email-to,
  #share-email-name {
    display: block;
  }

  select, input {
    @include appearance(none);
    border-radius: 0;
    border: 1px solid $border-color;
    @include box-shadow(none);
  }
}

// Only show slide start picker on slideview
.share-start {
  display: none
}

#new-player .share-start {
  display: block;
}

@media (max-height: 505px) {
  #lastScreen .modal-content-wrapper {
    overflow: hidden;
    max-height: none;
    background: $light-bg-color-modal;
    #modal-content {
      @include transform-origin(0, 0);
    }
  }
}

@media (max-height: 505px) and (min-height: 450px) {
  #lastScreen .modal-content-wrapper {
    height: $modal_height_default * $scale_xl;
    &.email-expanded {
      height: $modal_height_email_expanded * $scale_xl;
    }
    &.embed-expanded {
      height: $modal_height_embed_expanded * $scale_xl;
    }
    #modal-content {
      @include scale($scale_xl);
      width: $width_xl;
    }
  }
}

@media (max-height: 450px) and (min-height: 400px) {
  #lastScreen .modal-content-wrapper {
    height: $modal_height_default * $scale_large;
    &.email-expanded {
      height: $modal_height_email_expanded * $scale_large;
    }
    &.embed-expanded {
      height: $modal_height_embed_expanded * $scale_large;
    }
    #modal-content {
      @include scale($scale_large);
      width: $width_large;
    }
  }
}

@media (max-height: 400px) and (min-height: 350px) {
  #lastScreen .modal-content-wrapper {
    height: $modal_height_default * $scale_norm;
    &.email-expanded {
      height: $modal_height_email_expanded * $scale_norm;
    }
    &.embed-expanded {
      height: $modal_height_embed_expanded * $scale_norm;
    }
    #modal-content {
      @include scale($scale_norm);
      width: $width_norm;
    }
  }
}

@media (max-height: 350px) {
  #lastScreen .modal-content-wrapper {
    height: $modal_height_default * $scale_small;
    &.email-expanded {
      height: $modal_height_email_expanded * $scale_small;
    }
    &.embed-expanded {
      height: $modal_height_embed_expanded * $scale_small;
    }
    #modal-content {
      @include scale($scale_small);
      width: $width_small;
    }
  }
}
